import http from "../http-common";
import AuthService from "./AuthService";

export class TimetableService {
  protected url: string = "/timetable";

  timetable(start: number = 0, count: number = 14, locationUuid: string) {
    if (AuthService.isLoggedIn()) {
      return http.get(`${this.url}`, { headers: { ipAddress: AuthService.getIp(), Authorization: AuthService.token() }, params: { start: start, count: count, location: locationUuid }});
    } else {
      return http.get(`${this.url}`, { headers: { ipAddress: AuthService.getIp() }, params: { start: start, count: count, location: locationUuid }});
    }
  }

  get(event: string, date: string) {
    return http.get(`${this.url}/${event}`, { headers: { ipAddress: AuthService.getIp(), Authorization: AuthService.token() }, params: { date: date }});
  }

  registration(event: string, date: string, data: any[]) {
    return http.post(`${this.url}/${event}`, data, { headers: { ipAddress: AuthService.getIp(), Authorization: AuthService.token() }, params: { date: date }});
  }

  registrations(email: string | null) {
    return http.get(`${this.url}/registrations`, { headers: { ipAddress: AuthService.getIp(), Authorization: AuthService.token() }});
  }

  nextevent(location: string) {
    return http.get(`${this.url}/nextevent/${location}`, { headers: { ipAddress: AuthService.getIp() }});
  }

  verify(data: any[]) {
    return http.post(`${this.url}/verify`, data, { headers: { ipAddress: AuthService.getIp() }});
  }

  load(token: string) {
    return http.get(`${this.url}/registration/${token}`, { headers: { ipAddress: AuthService.getIp() } });
  }

  updateRegistration(token: string, registration: any) {
    return http.post(`${this.url}/registration/${token}`, registration, { headers: { ipAddress: AuthService.getIp(), Authorization: AuthService.token() } } );
  }

  cancelRegistration(token: string) {
    return http.delete(`${this.url}/registration/${token}`, { headers: { ipAddress: AuthService.getIp(), Authorization: AuthService.token() } });
  }

  request(email: string) {
    return http.post(`${this.url}/request`, { email: email }, { headers: { ipAddress: AuthService.getIp() } });
  }

  download() {
    return http.request({
      method: 'get',
      url: `${this.url}/download`,
      responseType: 'blob'
    });
  }

  loadMessage(uuid: string) {
    return http.get(`${this.url}/message/${uuid}`, { headers: { ipAddress: AuthService.getIp() } });
  }

  saveMessage(uuid: string, message: string) {
    return http.post(`${this.url}/message/${uuid}`, { message: message }, { headers: { ipAddress: AuthService.getIp() } });
  }

}

export default new TimetableService();
