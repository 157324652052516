
















































































































































import AuthService from "@/services/AuthService";
import LocationService from "@/services/LocationService";
import TimetableService from "@/services/TimetableService";
import { Component, Vue } from "vue-property-decorator";
import router from "../router/index";

@Component
export default class CreateAccount extends Vue {
  private user: any = {
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    services: [],
  };
  private password2: string = "";
  private consent: boolean = false;
  private errorCode: number = 0;
  private token: string = router.currentRoute.params.token || "";

  create() {
    this.errorCode = 0;
    this.user.username = this.user.email;

    AuthService.createAccount(this.user)
      .then((response) => {
        this.errorCode = response.status;
      })
      .catch((e) => {});
  }

  isEmailValid() {
    return (
      this.user.email.length > 0 &&
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.user.email)
    );
  }

  isPasswordValid() {
    return this.user.password.length > 6;
  }

  isPassword2Valid() {
    return this.user.password === this.password2;
  }

  isFormValid() {
    return (
      this.isEmailValid() &&
      this.isPasswordValid() &&
      this.isPassword2Valid() &&
      this.user.firstName &&
      this.user.lastName &&
      this.consent
    );
  }

  download() {
    TimetableService.download().then((response: any) => {
      var downloadURL = URL.createObjectURL(
        new Blob([response.data], { type: "application/pdf" })
      );
      const anchor = document.createElement("a");
      anchor.download = "Anmeldung_Gottesdienst_Datenschutz.pdf";
      anchor.href = downloadURL;
      anchor.target = "_blank";
      anchor.click();
    });
  }

  mounted() {
    if (this.token) {
      AuthService.enableAccount(this.token).then((response) =>
        router.push({ name: "login", query: { init: "1" } })
      );
    }
    this.errorCode = 1;
  }
}
